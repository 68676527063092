<template>
  <div class="content-document" @click.stop="onItemClick">
    <span class="content-document-item-name" @click="onItemClick">{{ documentName }}</span>
    <span class="content-document-item-price">￥{{ documentPrice.toFixed(2) }}</span>
  </div>
</template>

<script>
export default {
  name: "DocumentItem",
  components: {

  },
  props: {
    documentName: {
      type: String,
      default: '',
    },
    documentPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    onItemClick() {
      this.$emit("item-click");
    },
  },
};
</script>

<style scoped>
.content-document {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 10px 0px;
}

.content-document-item-name {
  width: calc(100% - 80px);
  cursor: pointer;
  color: #555;
  font-size: 14px;
}

.content-document-item-name:hover {
  color: #FF6602;
}

.content-document-item-price {
  color: #555;
  font-size: 14px;
}
</style>
