<template>
  <div class="document-box">
    <div class="document-name" @click="onItemClick">{{ documentName }}</div>
    <div class="document-price">全国统一零售价：￥{{ documentPrice }}</div>
  </div>
</template>

<script>
export default {
  name: "DocumentItem",
  components: {

  },
  props: {
    documentName: {
      type: String,
      default: '',
    },
    documentPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  methods: {
    onItemClick() {
      this.$emit("item-click");
    },
  },
};
</script>

<style scoped>
.document-box {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.document-name {
  color: gray;
  font-size: 14px;
  margin-bottom: 10px;
}

.document-name:hover {
  color: #FE5E03;
}

.document-price {
  width: 100%;
  display: inline-block;
  text-align: right;
  padding-right: 10px;
  padding-top: 5px;
  color: gray;
  font-size: 14px;
}
</style>
