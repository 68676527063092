<template>
  <div>
    <el-tabs type="card" v-model="activeValue">
      <el-tab-pane label="按照专业">
        <div class="pane-border">
          <el-row :gutter="10">
            <el-col :span="6">
              <search-select v-model="universityItem1" value-key="Key" placeholder="学校" @change="onSelectChange1">
                <search-option v-for="item in universityInfos" :value="item" :label="item.UniversityName"></search-option>
              </search-select>
            </el-col>
            <el-col :span="6">
              <search-select v-model="collegeItem1" value-key="Key" placeholder="学院" @change="onSelectChange2">
                <search-option v-for="item in collegeInfos" :value="item" :label="item.CollegeCode + item.CollegeName"></search-option>
              </search-select>
            </el-col>
            <el-col :span="6">
              <search-select v-model="majorItem1" value-key="Key" placeholder="专业" @change="onSelectChange3">
                <search-option v-for="item in majorInfos" :value="item" :label="item.MajorCode + item.MajorName">
                  <template>
                    {{ `${item.MajorCode}${item.MajorName}/${item.CollegeCode}${item.CollegeName}` }}
                  </template>
                </search-option>
              </search-select>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="按照初试科目">
        <div class="pane-border">
          <el-row :gutter="10">
            <el-col :span="6">
              <search-select v-model="universityItem2" value-key="Key" placeholder="学校" @change="onSelectChange4">
                <search-option v-for="item in universityInfos" :value="item" :label="item.UniversityName"></search-option>
              </search-select>
            </el-col>
            <el-col :span="6">
              <search-select v-model="examSubjectItem2" value-key="Key" placeholder="考试科目" @change="onSelectChange5">
                <search-option v-for="item in firstSubjectInfos" :value="item" :label="item.ExamSubjectCode + item.ExamSubjectName"></search-option>
              </search-select>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="按照复试科目">
        <div class="pane-border">
          <el-row :gutter="10">
            <el-col :span="6">
              <search-select v-model="universityItem3" value-key="Key" placeholder="学校" @change="onSelectChange6">
                <search-option v-for="item in universityInfos" :value="item" :label="item.UniversityName"></search-option>
              </search-select>
            </el-col>
            <el-col :span="6">
              <search-select v-model="examSubjectItem3" value-key="Key" placeholder="考试科目" @change="onSelectChange7">
                <search-option v-for="item in secondSubjectInfos" :value="item" :label="`${item.ExamSubjectName}/${item.MajorCode}${item.MajorName}`"></search-option>
              </search-select>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import RadioItem from "@/components/PC/RadioItem";
import RadioItemGroup from "@/components/PC/RadioItemGroup";
import CheckItem from "@/components/PC/CheckItem";
import CheckItemGroup from "@/components/PC/CheckItemGroup";
import SearchSelect from "@/components/PC/SearchSelect";
import SearchOption from "@/components/PC/SearchOption";
import Config from "@/api/service";
export default {
  name: "DocumentFilter",
  components: {
    RadioItem,
    RadioItemGroup,
    CheckItem,
    CheckItemGroup,
    SearchSelect,
    SearchOption,
  },
  props: {
    filterActive: {
      type: [Number, String],
      default: 0,
    },
    filterData1: {
      type: Object,
      default: {
        universityCode: '',
        collegeCode: '',
        majorCode: '',
      },
    },
    filterData2: {
      type: Object,
      default: {
        universityCode: '',
        examSubjectCode: '',
      },
    },
    filterData3: {
      type: Object,
      default: {
        universityCode: '',
        majorCode: '',
        examSubjectName: '',
      },
    },
  },
  computed: {
    universityItem1: {
      get: function () {
        return this.universityInfos.find(item => item.UniversityCode == this.filterData1.universityCode)
      },
      set: function (item) {
        this.filterData1.universityCode = item.UniversityCode;
      }
    },
    collegeItem1: {
      get: function () {
        return this.collegeInfos.find(item => item.UniversityCode == this.filterData1.universityCode && item.CollegeCode == this.filterData1.collegeCode)
      },
      set: function (item) {
        this.filterData1.collegeCode = item.CollegeCode;
      }
    },
    majorItem1: {
      get: function () {
        return this.majorInfos.find(item => item.UniversityCode == this.filterData1.universityCode && item.CollegeCode == this.filterData1.collegeCode && item.MajorCode == this.filterData1.majorCode)
      },
      set: function (item) {
        this.filterData1.collegeCode = item.CollegeCode;
        this.filterData1.majorCode = item.MajorCode;
      }
    },
    universityItem2: {
      get: function () {
        return this.universityInfos.find(item => item.UniversityCode == this.filterData2.universityCode)
      },
      set: function (item) {
        this.filterData2.universityCode = item.UniversityCode;
      }
    },
    examSubjectItem2: {
      get: function () {
        return this.firstSubjectInfos.find(item => item.ExamSubjectCode == this.filterData2.examSubjectCode)
      },
      set: function (item) {
        this.filterData2.examSubjectCode = item.ExamSubjectCode;
      }
    },
    universityItem3: {
      get: function () {
        return this.universityInfos.find(item => item.UniversityCode == this.filterData3.universityCode)
      },
      set: function (item) {
        this.filterData3.universityCode = item.UniversityCode;
      }
    },
    examSubjectItem3: {
      get: function () {
        return this.secondSubjectInfos.find(item => item.MajorCode == this.filterData3.majorCode && item.ExamSubjectName == this.filterData3.examSubjectName)
      },
      set: function (item) {
        this.filterData3.majorCode = item.MajorCode;
        this.filterData3.examSubjectName = item.ExamSubjectName;
      }
    },
    activeValue: {
      get: function () {
        return this.filterActive;
      },
      set: function (val) {
        this.$emit('update:filterActive', val);
      }
    },
  },
  data() {
    return {
      universityInfos: [],
      collegeInfos: [],
      majorInfos: [],
      firstSubjectInfos: [],
      secondSubjectInfos: [],
    };
  },
  methods: {
    //获取所有大学列表
    getAllUniversityList() {
      setTimeout(() => {
        var model = {};
        this.$axios
          .post(Config.document.filter.schoolList, model)
          .then((res) => {
            //列表
            this.universityInfos = res.data.Data.UniversityInfos;
            //设置Key
            this.universityInfos.forEach(item => {
              item.Key = item.UniversityCode;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //获取所有学院列表
    getAllCollegeList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.filterData1.universityCode
        };
        this.$axios
          .post(Config.document.filter.collegeList, model)
          .then((res) => {
            //列表
            this.collegeInfos = res.data.Data.CollegeInfos;
            //设置Key
            this.collegeInfos.forEach(item => {
              item.Key = item.UniversityCode + item.CollegeCode;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //获取所有专业列表
    getAllMajorList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.filterData1.universityCode,
          CollegeCode: this.filterData1.collegeCode
        };
        this.$axios
          .post(Config.document.filter.majorList, model)
          .then((res) => {
            //列表
            this.majorInfos = res.data.Data.MajorInfos;
            //设置Key
            this.majorInfos.forEach(item => {
              item.Key = item.UniversityCode + item.CollegeCode + item.MajorCode;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //获取所有初试科目列表
    getAllFirstSubjectList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.filterData2.universityCode
        };
        this.$axios
          .post(Config.document.filter.firstSubjectList, model)
          .then((res) => {
            //列表
            this.firstSubjectInfos = res.data.Data.FirstSubjectInfos;
            //设置Key
            this.firstSubjectInfos.forEach(item => {
              item.Key = item.ExamSubjectCode + item.ExamSubjectName;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //获取所有复试科目列表
    getAllSecondSubjectList() {
      setTimeout(() => {
        var model = {
          UniversityCode: this.filterData3.universityCode
        };
        this.$axios
          .post(Config.document.filter.secondSubjectList, model)
          .then((res) => {
            //列表
            this.secondSubjectInfos = res.data.Data.SecondSubjectInfos;
            //设置Key
            this.secondSubjectInfos.forEach(item => {
              item.Key = item.MajorCode + item.ExamSubjectName;
            });
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }, 0)
    },
    //选择大学改变（专业筛选）
    onSelectChange1() {
      this.filterData1.collegeCode = null;
      this.filterData1.majorCode = null;
      this.collegeInfos = [];
      this.majorInfos = [];
      this.getAllCollegeList();
      this.getAllMajorList();
      this.$emit('filter');
    },
    //选择学院改变（专业筛选）
    onSelectChange2() {
      this.filterData1.majorCode = null;
      this.majorInfos = [];
      this.getAllMajorList();
      this.$emit('filter');
    },
    //选择专业改变（专业筛选）
    onSelectChange3() {
      this.$emit('filter');
    },
    //选择大学改变（初试科目筛选）
    onSelectChange4() {
      this.filterData2.examSubjectCode = null;
      this.firstSubjectInfos = [];
      this.getAllFirstSubjectList();
      this.$emit('filter');
    },
    //选择考试科目改变（初试科目筛选）
    onSelectChange5() {
      this.$emit('filter');
    },
    //选择大学改变（复试科目筛选）
    onSelectChange6() {
      this.filterData3.majorCode = null;
      this.secondSubjectInfos = [];
      this.getAllSecondSubjectList();
      this.$emit('filter');
    },
    //选择考试科目改变（复试科目筛选）
    onSelectChange7() {
      this.$emit('filter');
    },
  },
  mounted() {
    this.getAllUniversityList();
  }
};
</script>

<style lang="less" scoped>
.pane-border {
  padding: 20px;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

::v-deep .el-tabs__header {
  margin: 0px;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
  border-radius: 0px;
}
</style>
